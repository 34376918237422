import * as React from 'react'
import  Layout from  '../../components/CrazyJack/Layout'
const Contact = () => {
    return (
    <Layout>
        <div>
        <h1>Contact ITIO</h1>
        <p>This is the work page</p>
        </div>
    </Layout>
    )
    }

export default Contact